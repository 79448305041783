import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import StepNavigation from './utils/StepNavigation';
import api from '../../services/apiService';
import CroppingModal from './crop/croppingModal'; // 引入新的裁剪模態框組件

const Step1_uploadImage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const fileInputRefs = [useRef(null), useRef(null), useRef(null)];

  // 初始化狀態，從 localStorage 中讀取合併的資料
  const [basicInfo, setBasicInfo] = useState(() => {
    const storedData = JSON.parse(localStorage.getItem('basicInfo')) || {};
    return {
      userName: storedData.userName || '',
      englishName: storedData.englishName || '',
      age: storedData.age || '',
      introduction: storedData.introduction || '', // 自我介紹說明
      personalMessage: storedData.personalMessage || '', // 我想說的話
      imageFilenames: storedData.imageFilenames || ['', '', ''],
    };
  });

  const [loadedImages, setLoadedImages] = useState(['', '', '']);
  const [isCropping, setIsCropping] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);

  // 導向下一步的函數
  const goToNextStep = () => {
    const { userName, englishName, age, introduction, personalMessage, imageFilenames } = basicInfo;

    // 檢查是否有未填寫的必填欄位
    if (
      !userName ||
      !englishName ||
      !age ||
      !imageFilenames[0] ||
      !imageFilenames[1] ||
      !imageFilenames[2]
    ) {
      Swal.fire({
        icon: 'warning',
        title: t('step1UploadImage.allFieldsRequired'),
        confirmButtonText: t('common.confirm'),
      });
      return;
    }

    // 若所有欄位都已填寫，則進入下一步
    navigate('/create-story/step2-choiceType');
  };

  // 當 basicInfo 改變時，儲存到 localStorage
  useEffect(() => {
    localStorage.setItem('basicInfo', JSON.stringify(basicInfo));
  }, [basicInfo]);

  // 當 imageFilenames 改變時，動態加載圖片
  useEffect(() => {
    const loadImages = async () => {
      const newLoadedImages = await Promise.all(
        basicInfo.imageFilenames.map(async (filename) => {
          if (filename) {
            try {
              const response = await api.get(`/api/stories/getfile/${filename}`, {
                responseType: 'blob', // 確保返回的是 Blob 文件
              });
              const imageBlob = response.data;
              const imageUrl = URL.createObjectURL(imageBlob);
              return imageUrl;
            } catch (error) {
              console.error(t('step1UploadImage.imageLoadError'), error);
              return '';
            }
          }
          return '';
        })
      );
      setLoadedImages(newLoadedImages);
    };

    if (basicInfo.imageFilenames.some((filename) => filename)) {
      loadImages(); // 當有 filename 存在時加載圖片
    }
  }, [basicInfo.imageFilenames, t]);

  // 更新的 uploadImage 函數，移動到 handleCropComplete 之前
  const uploadImage = async (index, croppedBlob) => {
    if (!croppedBlob) {
      Swal.fire({
        icon: 'warning',
        title: t('step1UploadImage.cropImageFirst'),
        confirmButtonText: t('common.confirm'),
      });
      return;
    }

    try {
      const formData = new FormData();
      formData.append('image', croppedBlob, `image${index}.jpg`)

      // 檢查 FormData 的內容
      for (let pair of formData.entries()) {
        console.log(`${pair[0]}, ${pair[1]}`);
      }

      const uploadResponse = await api.post('/api/stories/uploadimage', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log('Upload response:', uploadResponse);
      
      // 獲取上傳後的 filename
      const filename = uploadResponse.data.filename;

      // 更新圖片文件名到 state 並儲存到 localStorage
      const newImageFilenames = [...basicInfo.imageFilenames];
      newImageFilenames[index] = filename;
      setBasicInfo((prev) => ({ ...prev, imageFilenames: newImageFilenames }));
    } catch (error) {
      console.error('Image upload failed:', error);
      Swal.fire({
        icon: 'error',
        title: t('step1UploadImage.uploadFailed'),
        text: error.message,
        confirmButtonText: t('common.confirm'),
      });
    }
};

  // 裁剪完成後的處理
  const handleCropComplete = useCallback(
    async (croppedImageBlob) => {
      if (currentImageIndex === null) return;
  
      try {
        // 檢查裁剪後的圖片是否有生成
        if (!croppedImageBlob) {
          console.error('No cropped image data found');
          return;
        }
  
        console.log('Cropped image data:', croppedImageBlob);
  
        // 確認圖片數據類型和大小
        console.log('Cropped image type:', croppedImageBlob.type);
        console.log('Cropped image size:', croppedImageBlob.size);
  
        // 上傳圖片
        await uploadImage(currentImageIndex, croppedImageBlob);
  
        // 更新顯示的圖片
        const newLoadedImages = [...loadedImages];
        newLoadedImages[currentImageIndex] = URL.createObjectURL(croppedImageBlob);
        setLoadedImages(newLoadedImages);
  
        setIsCropping(false);
        setCurrentImageIndex(null);
        setImageSrc(null);
  
        // 重置文件輸入框
        if (fileInputRefs[currentImageIndex].current) {
          fileInputRefs[currentImageIndex].current.value = '';
        }
      } catch (error) {
        console.error(t('step1UploadImage.cropFailed'), error);
      }
    },
    [currentImageIndex, loadedImages, fileInputRefs, t]
  );
  

  // 開始裁剪圖片
  const startCropping = (index, file) => {
    if (!file) return;

    // 清空文件輸入框的值，避免選擇同一個文件時不觸發 onChange
    if (fileInputRefs[index].current) {
      fileInputRefs[index].current.value = '';
    }

    const imageUrl = URL.createObjectURL(file);
    setCurrentImageIndex(index);
    setImageSrc(imageUrl);
    setIsCropping(true);
  };

  return (
    <div className="w-full mx-auto">
      <div className="flex items-start justify-center mb-8 relative">
        <div className="flex items-start space-x-8"> {/* 使用 space-x-8 增加間距 */}
          <StepNavigation currentStep={1} />
          <button
            className="bg-gradient-to-r from-teal-400 to-blue-500 text-white h-12 w-32 rounded-full text-lg flex items-center justify-center shadow-lg"
            onClick={goToNextStep}
          >
            {t('common.next')}
          </button>
        </div>
      </div>

      <h1
        className="text-3xl font-bold text-center mb-6"
        style={{ fontFamily: 'Arial, sans-serif', color: '#dddddd' }}
      >
        {t('step1UploadImage.title')}
      </h1>
      <form className="flex flex-col items-center w-full max-w-full">
        <div className="mb-4 w-full md:w-1/2">
          <label className="block text-lg font-semibold" style={{ color: '#cccccc' }}>
            {t('step1UploadImage.nameLabel')}
          </label>
          <input
            type="text"
            value={basicInfo.userName}
            onChange={(e) => setBasicInfo((prev) => ({ ...prev, userName: e.target.value }))}
            className="w-full p-3 border border-gray-700 rounded mt-1"
            style={{
              fontFamily: 'Arial, sans-serif',
              color: '#eeeeee',
              backgroundColor: '#333333',
            }}
            placeholder={t('step1UploadImage.namePlaceholder')}
          />
        </div>
        <div className="mb-4 w-full md:w-1/2">
          <label className="block text-lg font-semibold" style={{ color: '#cccccc' }}>
            {t('step1UploadImage.englishNameLabel')}
          </label>
          <input
            type="text"
            value={basicInfo.englishName}
            onChange={(e) => setBasicInfo((prev) => ({ ...prev, englishName: e.target.value }))}
            className="w-full p-3 border border-gray-700 rounded mt-1"
            style={{
              fontFamily: 'Arial, sans-serif',
              color: '#eeeeee',
              backgroundColor: '#333333',
            }}
          />
        </div>
        <div className="mb-4 w-full md:w-1/2">
          <label className="block text-lg font-semibold mb-4" style={{ color: '#cccccc' }}>
            {t('step1UploadImage.ageLabel')}
          </label>
          <input
            type="number"
            value={basicInfo.age}
            onChange={(e) => setBasicInfo((prev) => ({ ...prev, age: e.target.value }))}
            className="w-full p-3 border border-gray-700 rounded mt-1"
            style={{
              fontFamily: 'Arial, sans-serif',
              color: '#eeeeee',
              backgroundColor: '#333333',
            }}
          />
        </div>

        {/* 自我介紹說明區域 */}
        <div className="mb-4 w-full md:w-1/2">
          <label className="block text-lg font-semibold" style={{ color: '#cccccc' }}>
            {t('step1UploadImage.introductionLabel')}
          </label>
          <textarea
            value={basicInfo.introduction}
            onChange={(e) => setBasicInfo((prev) => ({ ...prev, introduction: e.target.value }))}
            className="w-full p-3 border border-gray-700 rounded mt-1"
            style={{
              fontFamily: 'Arial, sans-serif',
              color: '#eeeeee',
              backgroundColor: '#333333',
              minHeight: '100px',
            }}
            placeholder={t('step1UploadImage.introductionPlaceholder')}
          />
        </div>

        {/* 我想說的話區域 */}
        <div className="mb-8 w-full md:w-1/2">
          <label className="block text-lg font-semibold" style={{ color: '#cccccc' }}>
            {t('step1UploadImage.personalMessageLabel')}
          </label>
          <textarea
            value={basicInfo.personalMessage}
            onChange={(e) => setBasicInfo((prev) => ({ ...prev, personalMessage: e.target.value }))}
            className="w-full p-3 border border-gray-700 rounded mt-1"
            style={{
              fontFamily: 'Arial, sans-serif',
              color: '#eeeeee',
              backgroundColor: '#333333',
              minHeight: '100px',
            }}
            placeholder={t('step1UploadImage.personalMessagePlaceholder')}
          />
        </div>

        {/* 照片上傳區域 */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8 w-full max-w-6xl mx-auto">
          {[0, 1, 2].map((index) => (
            <div
              key={index}
              className="relative bg-gray-800 p-4 border border-gray-600 rounded-lg text-center text-gray-300 cursor-pointer"
              style={{ borderRadius: '12px', width: '100%', aspectRatio: '6/4' }}
            >
              <input
                ref={fileInputRefs[index]}
                type="file"
                id={`fileInput${index}`}
                className="opacity-0 absolute inset-0 z-50 cursor-pointer"
                onChange={(e) => startCropping(index, e.target.files[0])}
              />
              {loadedImages[index] ? (
                <img
                  src={loadedImages[index]}
                  alt={t('step1UploadImage.uploadedImageAlt', { index: index + 1 })}
                  className="absolute top-0 left-0 w-full h-full object-cover"
                  style={{ borderRadius: '12px' }}
                />
              ) : (
                <span
                  className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                  style={{ display: 'block' }}
                >
                  {t('step1UploadImage.imageLabel', { index: index + 1 })}
                  <br />
                  {t('step1UploadImage.imageUploadReminder', { index: index + 1 })}
                </span>
              )}
              <div className="absolute bottom-0 left-0 w-full text-center p-2 bg-gray-900 bg-opacity-70 text-white">
                {index === 0
                  ? t('step1UploadImage.firstImageHint')
                  : t('step1UploadImage.otherImageHint')}
              </div>
            </div>
          ))}
        </div>

        {/* 裁剪圖片的對話框 */}
        {isCropping && imageSrc && (
          <CroppingModal
            open={isCropping}
            setOpen={setIsCropping}
            imageSrc={imageSrc}
            onCropComplete={handleCropComplete}
            inputRef={fileInputRefs[currentImageIndex]}
          />
        )}
      </form>
    </div>
  );
};

export default Step1_uploadImage;
