import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // 引入 useTranslation 用於語言化
import StepNavigation from './utils/StepNavigation';
import api from '../../services/apiService';
import ReactLoading from 'react-loading';
import Swal from 'sweetalert2';
import '../css/ImageSelector.css';

const Step5_picView = () => {
  const { t } = useTranslation(); // 使用 useTranslation 進行語言化
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [storyData, setStoryData] = useState(null);
  const [storyContent, setStoryContent] = useState(''); 
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageFilename, setImageFilename] = useState('');
  const [generatedImageUri, setGeneratedImageUri] = useState('');
  const [loading, setLoading] = useState(false);
  const isMounted = useRef(true); 

  useEffect(() => {
    const savedStoryData = JSON.parse(localStorage.getItem('storyData'));

    if (savedStoryData) {
      savedStoryData.storyChapters = savedStoryData.storyChapters.filter(
        (chapter) => chapter.chapter_number !== 1000
      );
      setStoryData(savedStoryData);
      setCurrentPage(savedStoryData.storyChapters[0]?.chapter_number || 0);
    }

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (storyData && currentPage !== null) {
      const selectedChapter = storyData.storyChapters.find(
        (chapter) => chapter.chapter_number === currentPage
      );
      if (selectedChapter) {
        setStoryContent(selectedChapter.description || '');
        if (selectedChapter.imageFilename && selectedChapter.imageFilename !== '') {
          setImageFilename(selectedChapter.imageFilename);
        } else {
          setImageFilename('');
          setGeneratedImageUri('');
        }
        setSelectedImage(
          selectedChapter.selectedImage !== undefined ? selectedChapter.selectedImage : null
        );
      }
    }
  }, [currentPage, storyData]);

  useEffect(() => {
    if (currentPage !== null && storyContent !== '' && imageFilename === '') {
      generateIllustration(false); 
    }
  }, [storyContent, imageFilename]);

  const generateIllustration = async (regenerate = false) => {
    if (!storyData) return;
    const updatedStoryData = { ...storyData };
    const chapterIndex = updatedStoryData.storyChapters.findIndex(
      (ch) => ch.chapter_number === currentPage
    );
    if (chapterIndex === -1) return;

    setLoading(true);
    setGeneratedImageUri('');
    setSelectedImage(null);

    try {
      const response = await api.post('/api/stories/generateImage', {
        storyListId: storyData.storyList.id,
        chapterNumber: currentPage,
        prompt: updatedStoryData.storyChapters[chapterIndex].prompt,
        description: storyContent,
        regenerate,
      });

      if (response.data && response.data.imageFilename) {
        const generatedFilename = response.data.imageFilename;
        updatedStoryData.storyChapters[chapterIndex].imageFilename = generatedFilename;
        setImageFilename(generatedFilename);
        setStoryData(updatedStoryData);
        localStorage.setItem('storyData', JSON.stringify(updatedStoryData));
      }
    } catch (error) {
      console.error(t('error.generationFailed'), error);
      Swal.fire({
        icon: 'error',
        title: t('error.title'),
        text: t('error.generationFailedMessage'),
        confirmButtonText: t('common.ok'),
      });
    } finally {
      setLoading(false);
    }
  };

  const handleImageSelect = (index) => {
    setSelectedImage(index);

    const updatedStoryData = { ...storyData };
    const chapterIndex = updatedStoryData.storyChapters.findIndex(
      (ch) => ch.chapter_number === currentPage
    );

    if (chapterIndex !== -1) {
      updatedStoryData.storyChapters[chapterIndex].selectedImage = index;
      setStoryData(updatedStoryData);
      localStorage.setItem('storyData', JSON.stringify(updatedStoryData));
    }
  };

  const sendSelectedImagesToBackend = async () => {
    if (storyData) {
      const selectedImages = storyData.storyChapters.map((chapter) => ({
        chapterNumber: chapter.chapter_number,
        selectedImage: chapter.selectedImage,
      }));

      try {
        const response = await api.post('/api/stories/updateSelectedImages', {
          storyListId: storyData.storyList.id,
          selectedImages,
        });

        if (response.data && response.data.success) {
          console.log(t('common.imageUpdateSuccess'));
        } else {
          console.error(t('error.imageUpdateFailed'));
        }
      } catch (error) {
        console.error(t('error.imageUpdateError'), error);
      }
    }
  };

  const handleStoryChange = (e) => {
    setStoryContent(e.target.value);
    const updatedStoryData = { ...storyData };
    const chapterIndex = updatedStoryData.storyChapters.findIndex(
      (ch) => ch.chapter_number === currentPage
    );
    if (chapterIndex !== -1) {
      updatedStoryData.storyChapters[chapterIndex].description = e.target.value;
      setStoryData(updatedStoryData);
      localStorage.setItem('storyData', JSON.stringify(updatedStoryData));
    }
  };

  const handlePageChange = (chapterNumber) => {
    sendSelectedImagesToBackend();
    setCurrentPage(chapterNumber);
  };

  const loadImage = async (filename) => {
    try {
      const response = await api.get(`/api/stories/getfile/${filename}`, {
        responseType: 'blob',
      });
      const imageBlob = response.data;
      const imageUrl = URL.createObjectURL(imageBlob);
      return imageUrl;
    } catch (error) {
      console.error(t('error.loadImageFailed'), error);
      return '';
    }
  };

  useEffect(() => {
    let imageUrl;
    if (imageFilename) {
      loadImage(imageFilename).then((url) => {
        imageUrl = url;
        setGeneratedImageUri(url);
      });
    } else {
      setGeneratedImageUri('');
    }

    return () => {
      if (imageUrl) {
        URL.revokeObjectURL(imageUrl);
      }
    };
  }, [imageFilename]);

  const allChaptersChecked = () => {
    return storyData?.storyChapters.every(
      (chapter) => chapter.selectedImage !== undefined
    );
  };

  const showWarning = () => {
    Swal.fire({
      icon: 'warning',
      title: t('warning.uncheckedChaptersTitle'),
      text: t('warning.uncheckedChaptersText'),
      confirmButtonText: t('common.ok'),
    });
  };

  const goToNextStep = () => {
    sendSelectedImagesToBackend();
    if (allChaptersChecked()) {
      navigate('/create-story/step6-bookViewer');
    } else {
      showWarning();
    }
  };

  const goBack = () => {
    navigate('/create-story/step4-storyList');
  };

  return (
    <div className="w-[90%] mx-auto">
      
      <div className="flex items-start justify-center mb-8 relative">
        <div className="flex items-start space-x-8"> 
          <button
            className="bg-gray-500 text-white h-12 w-32 rounded-full text-lg flex items-center justify-center"
            onClick={goBack}
          >
            {t('common.previous')}
          </button>
          <StepNavigation currentStep={5} />
          <button
            className="bg-gradient-to-r from-teal-400 to-blue-500 text-white h-12 w-32 rounded-full text-lg flex items-center justify-center shadow-lg"
            onClick={goToNextStep}
          >
            {t('common.next')}
          </button>
        </div>
      </div>

      <div className="flex justify-center space-x-2 mb-4 mt-4">
        {storyData?.storyChapters.map((chapter) => (
          <button
            key={chapter.chapter_number}
            onClick={() => handlePageChange(chapter.chapter_number)}
            className={`px-3 py-1 rounded-full text-sm ${
              currentPage === chapter.chapter_number
                ? 'bg-orange-400 text-white'
                : 'bg-gray-700 text-gray-300'
            }`}
          >
            {chapter.chapter_title === 'cover'
              ? t('step5_picView.cover')
              : chapter.chapter_title === 'firstPage'
              ? t('step5_picView.introduction')
              : chapter.chapter_title === 'lastPage'
              ? t('step5_picView.thankYou')
              : chapter.chapter_title === 'backcover'
              ? t('step5_picView.backCover')
              : `P${chapter.chapter_number}`}
            {chapter.selectedImage !== undefined && (
              <span className="ml-2 text-green-500">✔</span>
            )}
          </button>
        ))}
      </div>

      <div className="text-center mb-4">
        <span className="text-yellow-500 text-lg font-bold mr-2">{t('step5_picView.story')}</span>
        <textarea
          value={storyContent}
          onChange={handleStoryChange}
          className="w-full p-3 border border-gray-600 rounded-lg bg-gray-700 text-gray-200 resize-none"
          rows="3"
        />
      </div>

      <div className="text-center mb-8">
        {loading ? (
          <div className="flex justify-center items-center">
            <ReactLoading type="spin" color="#123abc" height={50} width={50} />
          </div>
        ) : (
          <button
            className="bg-orange-500 text-white py-2 px-6 rounded-full text-lg"
            onClick={() => generateIllustration(true)}
          >
            {t('step5_picView.generateIllustration')}
          </button>
        )}
        <p className="text-gray-400 mt-2">
          {t('step5_picView.regenerateIllustrationInfo')}
        </p>
      </div>

      <p className="text-center text-yellow-500 mb-4">{t('step5_picView.chooseImage')}</p>

      {generatedImageUri && (
        <div className="relative">
          <img
            src={generatedImageUri}
            alt="Generated Illustration"
            className="full-image"
          />

          {[1, 2, 3, 4].map((index) => (
            <div
              key={index}
              className={`image-section ${
                selectedImage === index ? 'selected' : ''
              }`}
              onClick={() => handleImageSelect(index)}
              style={{
                top: `${Math.floor((index - 1) / 2) * 50}%`,
                left: `${((index - 1) % 2) * 50}%`,
              }}
            >
              {selectedImage === index && (
                <div className="checkmark">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      <div style={{ marginBottom: '50px' }}></div>
    </div>
  );
};

export default Step5_picView;
