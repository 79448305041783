import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // 引入 useTranslation 用於語言化
import StepNavigation from './utils/StepNavigation';
import api from '../../services/apiService';
import ClipLoader from 'react-spinners/ClipLoader'; // 引入 ClipLoader

const Step4_storyList = () => {
  const { t } = useTranslation(); // 使用 useTranslation 進行語言化
  const navigate = useNavigate();
  const [storyData, setStoryData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const fetchStory = async () => {
      const savedStoryData = JSON.parse(localStorage.getItem('storyData'));
      const selectedTitle = JSON.parse(localStorage.getItem('selectedTitle'));
      const basicInfo = JSON.parse(localStorage.getItem('basicInfo'));

      const missingParams = [];
      if (!selectedTitle) missingParams.push(t('error.missingSelectedTitle'));
      if (!basicInfo?.userName) missingParams.push(t('error.missingUserName'));
      if (!basicInfo?.englishName) missingParams.push(t('error.missingEnglishName'));
      if (!basicInfo?.age) missingParams.push(t('error.missingAge'));
      if (basicInfo?.imageFilenames.some(link => !link)) missingParams.push(t('error.missingImageLinks'));

      if (missingParams.length > 0) {
        setErrorMessage(`${t('error.missingParams')}: ${missingParams.join('、')}`);
        setLoading(false);
        return;
      }

      // 確保 introduction 和 personal_message 欄位存在
      const introduction = basicInfo?.introduction || '';
      const personalMessage = basicInfo?.personalMessage || '';

      if (savedStoryData && savedStoryData.storyList.title_id === selectedTitle.id) {
        setStoryData(savedStoryData);
        setLoading(false);
      } else {
        try {
          const response = await api.post('/api/stories/getstorylist', {
            titleId: selectedTitle.id,
            title: selectedTitle.title,
            description: selectedTitle.description,
            userName: basicInfo.userName,
            userEnglishName: basicInfo.englishName,
            age: basicInfo.age,
            imageLinks: basicInfo.imageFilenames,
            introduction, // 傳遞 introduction 欄位
            personalMessage // 傳遞 personal_message 欄位
          });

          if (response.data) {
            setStoryData(response.data);
            localStorage.setItem('storyData', JSON.stringify(response.data));
          }
        } catch (error) {
          console.error(t('error.fetchFailed'), error);
          setErrorMessage(t('error.fetchErrorMessage'));
        } finally {
          setLoading(false);
        }
      }
    };

    fetchStory();
  }, [t]);


  const handleInputBlur = (value, chapterIndex, key) => {
    const updatedStoryData = { ...storyData };
    updatedStoryData.storyChapters[chapterIndex][key] = value;
    setStoryData(updatedStoryData);
    localStorage.setItem('storyData', JSON.stringify(updatedStoryData));

    updateStoryChapter(storyData.storyList.id, updatedStoryData.storyChapters[chapterIndex].chapter_number, {
      [key]: value
    });
  };

  const updateStoryChapter = async (storyListId, chapterNumber, updatedChapterData) => {
    try {
      const response = await api.post('/api/stories/updatestorychapter', {
        storyListId,
        chapterNumber,
        updatedChapterData
      });
      if (response.data) {
        console.log(t('common.chapterUpdated'));
      }
    } catch (error) {
      console.error(t('error.updateFailed'), error);
    }
  };

  const goToNextStep = () => {
    navigate('/create-story/step5-picView');
  };

  const goBack = () => {
    const selectedTitle = JSON.parse(localStorage.getItem('selectedTitle'));

    if (selectedTitle && selectedTitle.category_id === 9999) {
      navigate('/create-story/step3-selfCreate');
    } else {
      navigate('/create-story/step3-aiCreate');
    }
  };

  const refreshStoryData = () => {
    localStorage.removeItem('storyData');
    window.location.reload();
  };

  return (
    <div className="container mx-auto p-6">

      <div className="flex items-start justify-center mb-8 relative">
        <div className="flex items-start space-x-8">
          <button
            className="bg-gray-500 text-white h-12 w-32 rounded-full text-lg flex items-center justify-center"
            onClick={goBack}
          >
            {t('common.previous')}
          </button>
          <StepNavigation currentStep={4} />
          <button
            className="bg-gradient-to-r from-teal-400 to-blue-500 text-white h-12 w-32 rounded-full text-lg flex items-center justify-center shadow-lg"
            onClick={goToNextStep}
          >
            {t('common.next')}
          </button>
        </div>
      </div>

      <div className="text-center mb-8">
        <h2 className="text-3xl font-bold mb-4 text-white">{t('step4_storyList.title')}</h2>
      </div>

      <div className="flex justify-center items-center">
        <button className="bg-blue-500 text-white py-2 px-6 rounded-full text-lg" onClick={refreshStoryData}>
          {t('step4_storyList.refresh')}
        </button>
      </div>

      {loading ? (
        <div className="flex justify-center items-center">
          <ClipLoader color="#00bcd4" loading={loading} size={50} />
          <span className="text-white text-lg ml-4">{t('common.loading')}</span>
        </div>
      ) : errorMessage ? (
        <div className="text-red-500 text-lg text-center">{errorMessage}</div>
      ) : (
        <div className="bg-gray-800 text-gray-300 p-6 rounded-lg shadow-lg mb-8">
          <div className="mb-4">
            <h3 className="text-xl text-white">{t('step4_storyList.bookTitle')}: {storyData?.storyList.title}</h3>
            <p className="text-gray-400">{t('step4_storyList.subtitle')}: {storyData?.storyList.description}</p>
          </div>

          {/* 封面 */}
          <div className="mb-8">
            <h4 className="text-lg text-white">{t('step4_storyList.cover')}</h4>
            <textarea
              className="w-full p-3 border border-gray-600 rounded-lg bg-gray-700 text-gray-200 resize-none mt-2"
              rows="3"
              value={storyData?.storyChapters.find(chapter => chapter.chapter_title === 'cover')?.description || ''}
              onChange={(e) => setStoryData(prev => {
                const updatedStoryData = { ...prev };
                updatedStoryData.storyChapters[storyData.storyChapters.findIndex(ch => ch.chapter_title === 'cover')].description = e.target.value;
                return updatedStoryData;
              })}
              onBlur={(e) => handleInputBlur(e.target.value, storyData.storyChapters.findIndex(ch => ch.chapter_title === 'cover'), 'description')}
            />
          </div>

          {/* 介紹頁 */}
          <div className="mb-8">
            <h4 className="text-lg text-white">{t('step4_storyList.introduction')}</h4>
            <textarea
              className="w-full p-3 border border-gray-600 rounded-lg bg-gray-700 text-gray-200 resize-none mt-2"
              rows="3"
              value={storyData?.storyChapters.find(chapter => chapter.chapter_title === 'firstPage')?.description || ''}
              onChange={(e) => setStoryData(prev => {
                const updatedStoryData = { ...prev };
                updatedStoryData.storyChapters[storyData.storyChapters.findIndex(ch => ch.chapter_title === 'firstPage')].description = e.target.value;
                return updatedStoryData;
              })}
              onBlur={(e) => handleInputBlur(e.target.value, storyData.storyChapters.findIndex(ch => ch.chapter_title === 'firstPage'), 'description')}
            />
          </div>

          {/* 章節內容 */}
          {storyData?.storyChapters
            .filter(chapter => chapter.chapter_number > 0 && chapter.chapter_number < 998)
            .map((chapter, index) => (
              <div key={index} className="mb-4">
                <span className="text-gray-400">{`P:${chapter.chapter_number}`}</span>
                <textarea
                  className="w-full p-3 border border-gray-600 rounded-lg bg-gray-700 text-gray-200 resize-none mt-2"
                  rows="2"
                  value={chapter.description}
                  onChange={(e) => setStoryData(prev => {
                    const updatedStoryData = { ...prev };
                    updatedStoryData.storyChapters[index].description = e.target.value;
                    return updatedStoryData;
                  })}
                  onBlur={(e) => handleInputBlur(e.target.value, index, 'description')}
                />
              </div>
            ))}

          {/* 感謝頁 */}
          <div className="mb-8">
            <h4 className="text-lg text-white">{t('step4_storyList.thankYouPage')}</h4>
            <textarea
              className="w-full p-3 border border-gray-600 rounded-lg bg-gray-700 text-gray-200 resize-none mt-2"
              rows="3"
              value={storyData?.storyChapters.find(chapter => chapter.chapter_title === 'lastPage')?.description || ''}
              onChange={(e) => setStoryData(prev => {
                const updatedStoryData = { ...prev };
                updatedStoryData.storyChapters[storyData.storyChapters.findIndex(ch => ch.chapter_title === 'lastPage')].description = e.target.value;
                return updatedStoryData;
              })}
              onBlur={(e) => handleInputBlur(e.target.value, storyData.storyChapters.findIndex(ch => ch.chapter_title === 'lastPage'), 'description')}
            />
          </div>

          {/* 封底 */}
          <div className="mb-8">
            <h4 className="text-lg text-white">{t('step4_storyList.backCover')}</h4>
            <textarea
              className="w-full p-3 border border-gray-600 rounded-lg bg-gray-700 text-gray-200 resize-none mt-2"
              rows="3"
              value={storyData?.storyChapters.find(chapter => chapter.chapter_title === 'backcover')?.description || ''}
              onChange={(e) => setStoryData(prev => {
                const updatedStoryData = { ...prev };
                updatedStoryData.storyChapters[storyData.storyChapters.findIndex(ch => ch.chapter_title === 'backcover')].description = e.target.value;
                return updatedStoryData;
              })}
              onBlur={(e) => handleInputBlur(e.target.value, storyData.storyChapters.findIndex(ch => ch.chapter_title === 'backcover'), 'description')}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Step4_storyList;
