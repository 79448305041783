import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // 引入 useTranslation 用於語言化
import StepNavigation from './utils/StepNavigation';
import api from '../../services/apiService';
import { Button, Checkbox, FormControlLabel, Pagination, Box, CircularProgress } from '@mui/material'; // 引入CircularProgress
import languageStore from '../../stores/languageStore'; // 引入語言狀態

const Step3_aiCreate = () => {
  const { t, i18n } = useTranslation(); // 使用 useTranslation 進行語言化
  const navigate = useNavigate();
  const { language, initialized } = languageStore.getState();
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [titles, setTitles] = useState([]);
  const [selectedTitle, setSelectedTitle] = useState(null);
  const [currentCategoryPage, setCurrentCategoryPage] = useState(1);
  const [currentTitlePage, setCurrentTitlePage] = useState(1);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [loadingTitles, setLoadingTitles] = useState(false);
  const [fetchingCategories, setFetchingCategories] = useState(false); // 控制分類按鈕的加載狀態
  const [fetchingTitles, setFetchingTitles] = useState(false); // 控制標題按鈕的加載狀態
  const itemsPerPage = 8;
  const titlesPerPage = 8;

  // 清空 categories 和 titles
  const clearContent = () => {
    setCategories([]);
    setTitles([]);
    setSelectedCategory(null);
    setSelectedTitle(null);
    localStorage.removeItem('storyCategories');
    localStorage.removeItem('storyTitles');
    localStorage.removeItem('selectedCategory');
    localStorage.removeItem('selectedTitle');
  };

  useEffect(() => {
    if (initialized) {
      clearContent(); // 語言變更且初始化後才執行
    }

    setLoadingCategories(true);
    const storedCategories = localStorage.getItem('storyCategories');
    const storedSelectedTitle = JSON.parse(localStorage.getItem('selectedTitle')) || null;
    const storedStoryTitles = JSON.parse(localStorage.getItem('storyTitles')) || [];
    const storedSelectedCategory = JSON.parse(localStorage.getItem('selectedCategory'));

    setSelectedTitle(storedSelectedTitle);

    if (storedCategories) {
      const parsedCategories = JSON.parse(storedCategories);
      setCategories(parsedCategories);
      setLoadingCategories(false);

      if (storedSelectedCategory) {
        const selectedCategoryIndex = parsedCategories.findIndex(category => category.id == storedSelectedCategory.id);
        const calculatedCategoryPage = Math.floor(selectedCategoryIndex / itemsPerPage) + 1;
        setCurrentCategoryPage(calculatedCategoryPage);

        handleCategoryClick(storedSelectedCategory);

        const filteredTitles = storedStoryTitles.filter(title => title.categoryId == storedSelectedCategory.id);
        setTitles(filteredTitles);
        setLoadingTitles(false);
        if (storedSelectedTitle && filteredTitles.length > 0) {
          const selectedTitleIndex = filteredTitles.findIndex(title => title.title == storedSelectedTitle.title);
          if (selectedTitleIndex !== -1) {
            const calculatedTitlePage = Math.floor(selectedTitleIndex / titlesPerPage) + 1;
            setCurrentTitlePage(calculatedTitlePage);
          }
        }
      } else if (parsedCategories.length > 0) {
        handleCategoryClick(parsedCategories[0]);
      }
    } else {
      fetchCategories([], true);
    }
  }, [language]);

  const fetchCategories = async (existingCategoryIds = [], useFirstCategory = false) => {
    setFetchingCategories(true); // 顯示分類按鈕的加載狀態
    try {
      const basicInfo = JSON.parse(localStorage.getItem('basicInfo')) || {};
      const { userName, englishName, age } = basicInfo;
      const response = await api.get('/api/stories/getcategory', {
        params: {
          existingCategoryIds: existingCategoryIds.join(','),
          userName,
          englishName,
          age,
        },
      });
      const newCategories = response.data;
      const storedCategories = JSON.parse(localStorage.getItem('storyCategories')) || [];

      const combinedCategories = [...storedCategories, ...newCategories].reduce((acc, current) => {
        if (!acc.find(category => category.id === current.id)) {
          acc.push(current);
        }
        return acc;
      }, []);

      setCategories(combinedCategories);
      localStorage.setItem('storyCategories', JSON.stringify(combinedCategories));

      if (combinedCategories.length > storedCategories.length) {
        const newTotalPages = Math.ceil(combinedCategories.length / itemsPerPage);
        if (currentCategoryPage > newTotalPages) {
          setCurrentCategoryPage(newTotalPages);
        }
      }

      if ((!selectedCategory && combinedCategories.length > 0) || useFirstCategory) {
        handleCategoryClick(combinedCategories[0]);
      }
    } catch (error) {
      console.error('Failed to fetch categories:', error);
    } finally {
      setFetchingCategories(false); // 隱藏分類按鈕的加載狀態
    }
  };

  const fetchTitles = async (categoryId) => {
    setFetchingTitles(true); // 顯示標題按鈕的加載狀態
    const storedStoryTitles = JSON.parse(localStorage.getItem('storyTitles')) || [];
    const existingTitleIds = storedStoryTitles
      .filter(title => title.categoryId === categoryId)
      .map(title => title.id);

    if (existingTitleIds.length === 0) {
      setLoadingTitles(true);
    }

    try {
      const basicInfo = JSON.parse(localStorage.getItem('basicInfo')) || {};
      const { userName, englishName, age } = basicInfo;
      const response = await api.get('/api/stories/gettitles', {
        params: {
          categoryId,
          existingTitleIds: existingTitleIds.join(','),
          userName,
          englishName,
          age,
        },
      });

      const newTitles = response.data
        .filter(title => title.category_id === categoryId)
        .map(title => ({ ...title, categoryId }));

      const combinedTitles = [...storedStoryTitles, ...newTitles].reduce((acc, current) => {
        if (!acc.find(title => title.id === current.id && title.categoryId === current.categoryId)) {
          acc.push(current);
        }
        return acc;
      }, []);

      const categoryTitles = combinedTitles
        .filter(title => title.categoryId === categoryId);
      setTitles(categoryTitles);
      localStorage.setItem('storyTitles', JSON.stringify(combinedTitles));

      if (categoryTitles.length > storedStoryTitles.length) {
        const newTotalPages = Math.ceil(categoryTitles.length / titlesPerPage);
        if (currentTitlePage > newTotalPages) {
          setCurrentTitlePage(newTotalPages);
        }
      }
    } catch (error) {
      console.error('Failed to fetch titles:', error);
    } finally {
      setFetchingTitles(false); // 隱藏標題按鈕的加載狀態
      setLoadingTitles(false);
    }
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    localStorage.setItem('selectedCategory', JSON.stringify(category));

    setCurrentTitlePage(1);

    const storedStoryTitles = JSON.parse(localStorage.getItem('storyTitles')) || [];
    const filteredTitles = storedStoryTitles.filter(title => title.categoryId === category.id);

    setTitles([]);
    if (filteredTitles.length === 0) {
      setLoadingTitles(true);
    }

    if (filteredTitles.length > 0) {
      setTitles(filteredTitles);
      setLoadingTitles(false);
    } else {
      fetchTitles(category.id);
    }
  };

  const handleTitleSelect = (title) => {
    const isAlreadySelected = selectedTitle && selectedTitle.title === title.title && selectedTitle.categoryId == title.categoryId;

    if (isAlreadySelected) {
      setSelectedTitle(null);
      localStorage.setItem('selectedTitle', null);
    } else {
      setSelectedTitle(title);
      localStorage.setItem('selectedTitle', JSON.stringify(title));
    }
  };

  const refreshCategories = () => {
    const existingCategoryIds = categories.map(category => category.id);
    fetchCategories(existingCategoryIds);
  };

  const refreshTitles = () => {
    if (selectedCategory) {
      fetchTitles(selectedCategory.id);
    }
  };

  const goToNextStep = () => {
    navigate('/create-story/step4-storyList');
  };

  const goBack = () => {
    navigate('/create-story/step2-choiceType');
  };

  // 修正後的切片方式，加入結束索引
  const displayedCategories = categories.slice(
    (currentCategoryPage - 1) * itemsPerPage,
    currentCategoryPage * itemsPerPage
  );
  const displayedTitles = titles.slice(
    (currentTitlePage - 1) * titlesPerPage,
    currentTitlePage * titlesPerPage
  );

  return (
    <div className="container mx-auto p-6">

      <div className="flex items-start justify-center mb-8 relative">
        <div className="flex items-start space-x-8">
          <button
            className="bg-gray-500 text-white h-12 w-32 rounded-full text-lg flex items-center justify-center"
            onClick={goBack}
          >
            {t('common.previous')}
          </button>
          <StepNavigation currentStep={3} />
          <button
            className="bg-gradient-to-r from-teal-400 to-blue-500 text-white h-12 w-32 rounded-full text-lg flex items-center justify-center shadow-lg"
            onClick={goToNextStep}
          >
            {t('common.next')}
          </button>
        </div>
      </div>

      <div className="text-center mb-12">
        <h2 className="text-yellow-500 text-3xl font-bold mb-4">{t('step3_aiCreate.title')}</h2>
      </div>

      {loadingCategories && categories.length === 0 ? (
        <div className="flex justify-center items-center">
          <span className="text-white text-lg">{t('step3_aiCreate.loadingCategories')}</span>
        </div>
      ) : (
        <>
          <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-8 gap-2 mb-8">
            {displayedCategories.map((category, index) => (
              <button
                key={index}
                onClick={() => handleCategoryClick(category)}
                className={`py-2 px-4 rounded-full text-lg ${category.id == selectedCategory?.id ? 'bg-teal-500 text-white' : 'bg-gray-700 text-white'}`}>
                {category.category}
              </button>
            ))}
          </div>

          {categories.length > 0 && (
            <Box display="flex" justifyContent="center" alignItems="center" mb={8}>
              <Pagination
                count={Math.ceil(categories.length / itemsPerPage)}
                page={currentCategoryPage}
                onChange={(event, value) => setCurrentCategoryPage(value)}
                variant="outlined"
                shape="rounded"
                sx={{
                  '& .MuiPaginationItem-root': {
                    color: '#ffffff',
                    borderColor: '#D3D3D3',
                  },
                  '& .Mui-selected': {
                    backgroundColor: '#00bcd4',
                    color: '#ffffff',
                    borderColor: '#00bcd4',
                  },
                  '& .MuiPaginationItem-root:hover': {
                    backgroundColor: '#424242',
                  },
                }}
              />
              <Button
                onClick={refreshCategories}
                variant="outlined"
                sx={{
                  mx: 2,
                  color: '#ffffff',
                  borderColor: '#00bcd4',
                  '&:hover': {
                    backgroundColor: '#00bcd4',
                    borderColor: '#00bcd4',
                  },
                }}
              >
                {t('step3_aiCreate.refreshCategories')}
                {fetchingCategories && <CircularProgress size={20} sx={{ ml: 2, color: '#ffffff' }} />}
              </Button>
            </Box>
          )}
        </>
      )}

      {loadingTitles ? (
        <div className="flex justify-center items-center">
          <span className="text-white text-lg">{t('step3_aiCreate.loadingTitles')}</span>
        </div>
      ) : titles.length > 0 ? (
        <>
          <div className="text-left mb-8 text-gray-300 bg-gray-800 p-4 rounded-lg">
            {displayedTitles.map((title, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={
                      selectedTitle &&
                      selectedTitle.title === title.title &&
                      selectedTitle.categoryId == title.categoryId
                    }
                    onChange={() => handleTitleSelect(title)}
                    sx={{
                      color: 'white',
                      '&.Mui-checked': {
                        color: '#00bcd4',
                      },
                    }}
                  />
                }
                label={`${title.title}：${title.description}`}
                sx={{ marginBottom: '16px', color: 'white' }}
              />
            ))}
          </div>

          <Box display="flex" justifyContent="center" alignItems="center" mb={8}>
            <Pagination
              count={Math.ceil(titles.length / titlesPerPage)}
              page={currentTitlePage}
              onChange={(event, value) => setCurrentTitlePage(value)}
              variant="outlined"
              shape="rounded"
              sx={{
                '& .MuiPaginationItem-root': {
                  color: '#ffffff',
                  borderColor: '#D3D3D3',
                },
                '& .Mui-selected': {
                  backgroundColor: '#00bcd4',
                  color: '#ffffff',
                  borderColor: '#D3D3D3',
                },
                '& .MuiPaginationItem-root:hover': {
                  backgroundColor: '#424242',
                },
              }}
            />
            <Button onClick={refreshTitles} variant="outlined" sx={{ mx: 2 }}>
              {t('step3_aiCreate.refreshTitles')}
              {fetchingTitles && <CircularProgress size={20} sx={{ ml: 2, color: '#ffffff' }} />}
            </Button>
          </Box>
        </>
      ) : (
        <div className="flex justify-center items-center">
          <span className="text-white text-lg">{t('step3_aiCreate.noTitles')}</span>
        </div>
      )}

    </div>
  );
};

export default Step3_aiCreate;
